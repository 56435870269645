import "../styles/globals.scss";
import { ginger, lora } from "../styles/fonts";
import { Analytics } from '@vercel/analytics/react';
import Head from "next/head";
import Script from "next/script";
import { Toaster } from 'react-hot-toast';
import StoreProvider from "./StoreProvider";
import Layout from "./Layout";
import { isMobile } from 'react-device-detect';

function addSchema() {
    return {
        __html: `{

                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "PillUp",
                    "alternateName": "Pill up",
                    "foundingDate": "2022",
                    "url": "https://www.pillup.com/",
                    "logo": "https://www.pillup.com/assets/logo.svg",
                    "description": "PillUp is a modern pharmacy designed to make your life easier.",
                    "contactPoint": [{
                        "@type": "ContactPoint",
                    "telephone": "+919818360666",
                    "email": "hello@pillup.com",
                    "areaServed": ["India"],

                    "availableLanguage": ["English", "Hindi"]
            }],
                    "address": {
                    "@context": "https://schema.org",
                    "@type": "PostalAddress",
                    "streetAddress": "307 III-floor, HB Twin Tower-II",
                    "addressLocality": "Netaji Subhash Place, Pitampura",
                    "addressRegion": "Delhi",
                    "postalCode": "110034 ",
                    "addressCountry": "IN"
            }
        }`,
    };
}


function MyApp({ Component, pageProps }) {
    return (
        <StoreProvider>
            {/* <Script
                type="text/javascript"
                src="
            https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
                id="aisensy-wa-widget"
                widget-id="6GNX7W"
            >
            </Script> */}
            <Script id="wati-chat" strategy="afterInteractive">
                {`
                    var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?71534';
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = url;
                    var options = {
                        "enabled":true,
                        "chatButtonSetting":{
                            "ctaText":"Chat with us",
                            "borderRadius":"25",
                            "marginLeft": "0",
                            "marginRight": "20",
                            "marginBottom": "20",
                            "ctaIconWATI":false,
                            "position":"right"
                        },
                        "brandSetting":{
                            "brandName":"PillUp",
                            "brandSubTitle":"undefined",
                            "brandImg":"https://pillup.com/assets/logo.svg",
                            "welcomeText":"Hi there!\\nHow can I help you?",
                            "messageText":"Hi I am interested in ordering from PillUp",
                            "backgroundColor":"#00e785",
                            "ctaText":"Chat with us",
                            "borderRadius":"25",
                            "autoShow":${isMobile ? false : true},
                            "phoneNumber":"919266607026"
                        }
                    };
                    s.onload = function() {
                        CreateWhatsappChatWidget(options);
                    };
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                `}
            </Script>
            <Script id="478156727574330" strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                            n.callMethod ?
                                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                        };
                        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                        n.queue = []; t = b.createElement(e); t.async = !0;
                        t.src = v; s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '478156727574330');
                        fbq('track', 'PageView');`
                }}
            />
            <Script id="827485051618921" strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                        n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
                    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                    n.queue = []; t = b.createElement(e); t.async = !0;
                    t.src = v; s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '827485051618921');
                    fbq('track', 'PageView');`
                }}
            />
            <Script id="3199032427079736" strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
                    n = f.fbq = function () {
                        n.callMethod
                            ? n.callMethod.apply(n, arguments)
                            : n.queue.push(arguments);
            };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
        })(
                    window,
                    document,
                    'script',
                    'https://connect.facebook.net/en_US/fbevents.js'
                    );
                    fbq('init', '3199032427079736');
                    fbq('track', 'PageView');`
                }}
            />

            <Script id="658577482285597" strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                        n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
                    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                    n.queue = []; t = b.createElement(e); t.async = !0;
                    t.src = v; s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '658577482285597');
                    fbq('track', 'PageView');`
                }}
            />

            <Script id="11100836693" strategy="afterInteractive"
                type="application/ld+json"
                dangerouslySetInnerHTML={addSchema()}
                key="product-jsonld"
            />

            <Script id="GTM-NJPM88J" strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-NJPM88J');`
                }}
            />

            <Head>
                <meta property='og:title'
                    content='PillUp - Get Pre-Sorted Medications At Your Door Step along with medicine reminders.' />
                <meta property='og:image' content='https://www.pillup.com/assets/thumbnail.png' />
                <meta property='og:description' content='Switch to the care you deserve' />
                <meta property='og:url' content='https://www.pillup.com' />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='627' />
                <meta property="og:type" content='website' />

                <meta charset="UTF-8" />
                <meta name="facebook-domain-verification" content="zqpv3e40le2fquus3fzu1kksxr6swk" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0 , user-scalable=no" />

                <link rel="apple-touch-icon" sizes="180x180" href="/assets/icons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/icons/favicon-16x16.png" />
                <link rel="manifest" href="/assets/icons/site.webmanifest" />
                <link rel="mask-icon" href="/assets/icons/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                <title>PillUp</title>
            </Head>
            <main className={lora.className}>
                <Layout>
                    <Toaster
                        position="top-center"
                        toastOptions={{
                            style: ginger.style
                        }}
                    />
                    <Component {...pageProps} />
                    <Analytics />
                </Layout>
            </main>
        </StoreProvider>
    );
}

export default MyApp;
