import axios from 'axios';
import { API_BASE_PATH, refreshTokenKey, TOKEN_EXPIRY_THRESHOLD, tokenExpiresAtKey, tokenKey, userKey, userObjectAttributes } from '../constants/constants';
import { getCookie, getDefaultHeaders, getNewToken, removeCookie, setCookie } from './helperMethods';
import errors from '../constants/errors';
import { store } from "../store/store"
import { authActions } from '../store/features/auth/authSlice';
import { jwtDecode } from "jwt-decode";
import _ from 'lodash';
import moment from 'moment/moment';

const client = axios.create({
    baseURL: API_BASE_PATH,
});

export const axiosInstance = async ({
    ...options
}) => {

    const onSuccess = (response) => {
        return response;
    };

    const onError = (error) => {
        if (error?.response?.status === 403) return error.response;
        if (error?.response?.status === 401) {
            logoutHelper()
        }
        return error;
    };
    let headers = getDefaultHeaders();

    client.defaults.headers = { ...headers, ...options.headers };
    delete options.headers;

    return client(options).then(onSuccess).catch(onError);
};

export const request = async ({
    ...options
}) => {

    const checkRefresh = Object.prototype.hasOwnProperty.call(options, 'checkRefresh') ? options['checkRefresh'] : true

    let result = {
        success: false,
        data: {},
        error: ""
    }

    if (checkRefresh && getCookie(tokenExpiresAtKey)) {
        let authTokenExpiresAt = moment(parseInt(getCookie(tokenExpiresAtKey)))

        const diff = authTokenExpiresAt.diff(moment(), "seconds")

        if (diff <= TOKEN_EXPIRY_THRESHOLD && getCookie(tokenKey) && getCookie(refreshTokenKey)) {
            const token = await getNewToken(JSON.parse(getCookie(tokenKey)), JSON.parse(getCookie(refreshTokenKey)))

            if (token) {
                setCookie(tokenKey, token)
                store.dispatch(authActions.setToken(token))

                const decoded = jwtDecode(token?.split(':')[0])

                if (decoded) {
                    const userObject = _.pick(decoded, userObjectAttributes)

                    setCookie(userKey, userObject)
                    store.dispatch(authActions.setUser(userObject))
                    setCookie(tokenExpiresAtKey, decoded?.expiresAt)
                }
            }
        }
    }

    try {
        let res = await axiosInstance({ ...options });

        const { success, data, error } = res.data

        if (success) {
            result = {
                success: true,
                data,
                error: ""
            }
        } else {
            result = {
                success: false,
                data: {},
                error: error || errors.apiError
            }
        }
    } catch (error) {
        result = {
            success: false,
            data: {},
            error: errors.somethingWentWrong
        }
    }

    return result
};

export const logoutHelper = () => {
    removeCookie(tokenKey)
    removeCookie(userKey)
    removeCookie(refreshTokenKey)
    removeCookie(tokenExpiresAtKey)
    window.Moengage.destroy_session()
    window.location.replace("/order")
}
