const images = {
    pillupTatvaLogo: "/assets/Order/logo.png",
    pillupLogo: "/assets/logo.svg",
    whatsAppLogo: "/assets/whatsapp.png",
    orderBannerSmall: "/assets/Order/orderBannerSmall.png",
    orderBannerBig: "/assets/Order/orderBannerBig.png",
    stockImageIcon: "/assets/Order/stockImageIcon.png",
    pdfIcon: "/assets/Order/pdf.png",
    loader: "/assets/Order/loader.gif",
    helpIcon: "/assets/Order/helpIcon.png",
    accountIcon: "/assets/Order/accountIcon.png",
    avatarIcon: "/assets/Order/avatarIcon.png",
    medicineBox: "/assets/Order/medicineBox.png",
    packedIcon: "/assets/Order/packedIcon.png",
    safeIcon: "/assets/Order/safeIcon.png",
    orderPlacedIcon: "/assets/Order/orderPlacedIcon.png",
    morningIcon: "/assets/Consent/morningIcon.png",
    noonIcon: "/assets/Consent/noonIcon.png",
    eveningIcon: "/assets/Consent/eveningIcon.png",
    nightIcon: "/assets/Consent/nightIcon.png",
    medicineIcon: "/assets/Consent/medicineIcon.png",
    medicineYellowIcon: "/assets/Consent/medicineYellowIcon.png",
    medicinePinkIcon: "/assets/Consent/medicinePinkIcon.png",
    blisteredIcon: "/assets/Consent/blisteredIcon.png",
    deblisteredIcon: "/assets/Consent/deblisteredIcon.png",
    deblisteredPillIcon: "/assets/Consent/deblisteredPillIcon.png",
    blisteredPillIcon: "/assets/Consent/blisteredPillIcon.png",
    profileIcon: "/assets/Order/profile.png",
    logoutIcon: "/assets/Order/logout.png",
    orderCart: "/assets/Order/orderCart.png",
    chevronLeft: "/assets/Order/chevronLeft.png",
    chevronRight: "/assets/Order/chevronRight.png",
    house: "/assets/Order/house.png",
    dangerIcon: "/assets/Order/dangerIcon.png",
    checkSuccess: "/assets/Order/checkSuccess.png",
    noOrderImage: "/assets/Order/noOrderImage.png",
}

export default images