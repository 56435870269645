import {
    faFileUpload,
    faPhoneVolume,
    faCheckCircle,
    faBoxOpen,
    faTruckFast
} from '@fortawesome/free-solid-svg-icons'

const API_BASE_PATH = process.env.NEXT_PUBLIC_BASE_URL

const userKey = 'user_profile';
const tokenKey = 'user_token';
const refreshTokenKey = "refresh_token";
const tokenExpiresAtKey = "token_expires_at"
const utmSourceKey = "utm_source"
const utmCampaignKey = "utm_campaign"

const TOKEN_EXPIRY_THRESHOLD = 100

const genderEnum = {
    male: "M",
    female: "F",
    other: "P"
}

const patientTypeEnum = {
    myself: "myself",
    others: "others"
}

const orderListingTabsEnum = {
    active: "active",
    past: "past",
    callbacks: "callback"
}

const orderTimelineStatusCodes = {
    ORDER_PRESCRIPTION_SUBMITTED: "ORDER_PRESCRIPTION_SUBMITTED",
    ORDER_PLACED: "ORDER_PLACED",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    DELIVERED: "DELIVERED",
    ORDER_CANCELLED: "ORDER_CANCELLED"
}

const callbackTimelineStatusCodes = {
    NEW: "NEW",
    CALLBACK: "CALLBACK",
    NO_ANSWER: "NO_ANSWER",
    LOST: "LOST",
    CONVERTED: "CONVERTED"
}

const orderStatusCodes = {
    ORDER_PENDING: "ORDER_PENDING",
    ORDER_CANCELLED: "ORDER_CANCELLED",
    OCS_PROCESSING: "OCS_PROCESSING",
    CALL_PENDING: "CALL_PENDING",
    USER_DRX_PENDING: "USER_DRX_PENDING",
    USER_DRX_REJECTED: "USER_DRX_REJECTED",
    USER_DRX_ACCEPTED: "USER_DRX_ACCEPTED",
    SHIPPED_MEDICINES_PACKED: "SHIPPED_MEDICINES_PACKED",
    SHIPPED_IN_TRANSIT: "SHIPPED_IN_TRANSIT",
    DELIVERED: "DELIVERED",
    CONSENT_PENDING: "CONSENT_PENDING",
    CONSENT_GRANTED: "CONSENT_GRANTED",
}

const callbackStatusConstants = {
    NEW: "NEW",
    CALLBACK: "CALLBACK",
    NO_ANSWER: "NO_ANSWER",
    CONVERTED: "CONVERTED",
    LOST: "LOST"
}

const medicineTime = {
    morning: "morning",
    noon: "noon",
    evening: "evening",
    night: "night",
}

const userObjectAttributes = ['id', 'contact_no', 'dob', 'gender', 'mappingIds', 'name', 'country_code', 'profile_pic']

const orderingSteps = [
    {
        number: 1,
        title: "Upload prescription",
        description: "Share your prescription securely",
        icon: faFileUpload
    },
    {
        number: 2,
        title: "Callback",
        description: "A callback is scheduled",
        icon: faPhoneVolume
    },
    {
        number: 3,
        title: "Verification",
        description: "Receive a phone call for order details confirmation",
        icon: faCheckCircle
    },
    {
        number: 4,
        title: "Processing",
        description: "Careful packaging of medicines",
        icon: faBoxOpen
    },
    {
        number: 5,
        title: "Delivery",
        description: "Safe delivery to your doorstep",
        icon: faTruckFast
    }
]

const orderPlacedSteps = [
    {
        number: 1,
        title: "Wait for Callback",
        description: "We'll call you for confirming order details",
        icon: faPhoneVolume
    },
    {
        number: 2,
        title: "Processing",
        description: "Careful packaging of medicines",
        icon: faBoxOpen
    },
    {
        number: 3,
        title: "Delivery",
        description: "Safe delivery to your doorstep",
        icon: faTruckFast
    }
]

export {
    API_BASE_PATH,
    userKey,
    tokenKey,
    refreshTokenKey,
    tokenExpiresAtKey,
    utmSourceKey,
    utmCampaignKey,
    genderEnum,
    patientTypeEnum,
    orderListingTabsEnum,
    orderTimelineStatusCodes,
    callbackTimelineStatusCodes,
    orderStatusCodes,
    medicineTime,
    callbackStatusConstants,
    userObjectAttributes,
    TOKEN_EXPIRY_THRESHOLD,
    orderingSteps,
    orderPlacedSteps
}